export const CULTUREFLOW_CASE_DATA = [
    {
        superTitle: 'Case Study: Cultureflow · 7 min read time',
        title: 'Teaching te reo Māori (the indigenous language of New Zealand)',
        superImage: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_8_qjug85.png',
        superImageAlt: 'Cultureflow Logo',
        superImageHeight: '56',
        superImageClass: 'mb-3',
        spacing: 'pt-2 pt-sm-5 pb-4',
        background: 'bg-cream',
        breadcrumbs: [
            {
                name: 'Case Studies',
                destination: '/cases'
            },
            {
                name: 'Cultureflow',
                destination: '/cases/rush-digital',
                last: true
            }
        ],
        content: [
            '<em>How do you teach a culture online in an engaging way?</em>',
        ],
        contentImage: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1625043584/Cultureflow_Case_Study_Cover_jkyoau.png',
        contentImageAlt: 'Office Space',
        contentImageClass: 'w-100 h-100'
    },
    {
        title: 'The problem',
        background: 'bg-white',
        spacing: 'pt-3 pb-2',
        listContent:[
            'Sarah, the managing director for Cultureflow, taught others te reo Māori through workbooks and one-on-one teaching via Zoom',
            'She wanted to create an online resource to teach te reo Māori but didn\'t know where to start or how to go about building it.' ,
            'Sarah was also time-poor. She wanted to work on her business instead of in it. To do this, she wanted to move towards a \'one-to-anyone at any time\' approach that would be as effective '
        ],
        listItemClasses: 'mb-4'
    },
    {
        title: 'The challenge',
        background: 'bg-white',
        spacing: 'pt-0 pb-2',
        listContent:[
            'Create a resource that can help adult learners learn te reo Māori. ',
            'The resource needs to be written and published in six weeks (85 pages of Sarah\'s original textbook converted into an online resource)',
            'The resource needs to be web and mobile friendly'
        ],
        listItemClasses: 'mb-4'
    },
    {
        title: 'What we did',
        background: 'bg-white',
        spacing: 'pt-0 pb-2',
        listContent:[
            'Asked ourselves \'What does it mean to learn a language?\'',
            'Researched other learning resources and apps',
            'Learnt the psychology of learning a language',
            'Tried to better understand te reo Māori from first principles'
        ],
        listItemClasses: 'mb-4',
    },
    {
        title: 'The outcome',
        background: 'bg-white',
        spacing: 'pt-0 pb-4',
        listContent:[
            'Suggested tweaks to the scope and built something beyond expectations in the same timeframe (six weeks)',
            'Received amazing reviews from learners and experts on course design and user experience',
            'The course is now being sold to New Zealand government departments and large New Zealand businesses.',
        ],
        listItemClasses: 'mb-4',
    },
    {
        background: 'bg-cream',
        spacing: 'py-4',
        title: 'How do you teach a culture online in an engaging way?',
        content: [
            'This was the challenge put to us by Sarah Reo, the founder of Cultureflow. Cultureflow has been teaching te reo Māori since 2002. When Sarah first started Cultureflow, all her teachings were turned into books and CDs. At the time, turning learning into CDs would have been considered cutting edge. Around twenty years later, she asked us \'What would cutting edge look like today?\'',
            'An easy way to solve the problem would have been to convert all the resources Sarah built and turn them into an e-book or a video-based course. But that is not how a language is learnt — especially not te reo Māori — an oral language. We wanted to build something true to Sarah\'s purpose — to empower every New Zealander to engage in te reo Māori confidently.',
            'We started by looking at what it meant to learn a language today compared to twenty years ago. While books still existed, we found that most people engaged with apps like Duolingo and Memrise. There were other tools such as language exchange websites, meet-ups and online classes that you could attend.',            
            'We liked the idea of a student being able to engage with learning the language. We knew that they needed to get corrective feedback every time they made a mistake to learn the language quickly. Creating a safe space for someone to fail and then learn was a must.',
            'So how did we do this? We took a question first approach to learning and ensuring supportive feedback whenever a question is answered incorrectly. Different from other learning applications, we provided notes and explanations to every question to give learners confidence in answering questions without fear of failure. The notes could also be saved for reference, so learners didn\'t need the feel urge to memorise but rather to learn.',            
            '<hr class="my-5"/>',
            'Most popular language learning apps heavily lean towards learning words and not around the fundamental context or meaning. We believed that to learn a language, you need to understand the words and the culture and history to appreciate how to use the language fully — and not just speak it. For example, to say \'How are you?\' in te reo Māori, you would say \'Kei te pēhea koe?\' This is an English to te reo Māori translation. But if you were to take it from the other way around — from te reo Māori to English, it would translate to \'What is the nature of you?\' or \'How are you really feeling?\' This is a much more powerful question than simply \'How are you?\' Tie this in with the context of mental health and Māori health models, and suddenly you have a lot more layers to the learning and much deeper student engagement. ',
            '<hr class="my-5"/>',
            'We went beyond the scope (on our own accord) of converting the textbooks Sarah wrote and tied in the legends and stories of the Māori people right into the start. This ensured that students would learn the language and the stories together as opposed to them being separated. Now, Sarah\'s course does not start with how to say \'Hello\'. Instead, the course begins with what it means to be Māori and the underlying value-based system (Tikanga) that underpins the language itself.'  ,            
            'The other thing that we thought was really important in learning a language was ensuring that it had audio (especially since te reo Māori is an oral language and pronunciation is very important). We made sure that there was narration for each answer (even if it was wrong) and the explanation. This was custom built — from the audio recording tool to the UX for a student. This ensured that students could learn the pronunciation of the words and take the best parts of being in a classroom while learning at their own pace. ',
            '<hr class="my-5"/>',
            'We believe that learning a language is like building a skyscraper. It takes a lot of work, but it can be put up relatively quickly if the foundation is right. We took a first-principles approach to the process of learning a language and applied it in a way that was appropriate to learning a predominately oral language. And we did all of this in six weeks.'
        ],
        image:'https://res.cloudinary.com/dqpij5loy/image/upload/q_auto/v1611968005/Rio_tinto_river_CarolStoker_NASA_Ames_Research_Center_rtw0gp.jpg',
        imageAlt: 'Acid mine drainage can turn waterways orange to red',
        imageCaption: 'Rio Tinto in Spain — Acid mine drainage can turn waterways orange to red'
    }
]